<template>
  <div>
    <div class="bg-white p10 bd-radius m-t-15 p-t-15">
      <div class="form-content">
        <div><label><b>{{ $t('FLIGHTS.PASSENGER') }} {{ passenger_ind + 1 }} ({{ category_title }})</b></label></div>
        <div class="d-flex justify-content-between w-100">
          <div class="w-50 px10">
            <label :for="'name_' + passenger_ind">{{ $t('FLIGHTS.FIRST_NAME') }}</label>
            <input :id="'name_' + passenger_ind" type="text" class="input bd-radius" v-model="passenger_data.name" :disabled="view_mode" />
          </div>
          <div class="w-50 px10">
            <label :for="'surname_' + passenger_ind">{{ $t('FLIGHTS.LAST_NAME') }}</label>
            <input :id="'surname_' + passenger_ind" type="text" class="input bd-radius" v-model="passenger_data.surname" :disabled="view_mode" />
          </div>
        </div>
        <div class="d-flex justify-content-between w-100">
          <div class="w-30 px10">
            <label :for="'nationality_' + passenger_ind">{{ $t('FLIGHTS.NATIONALITY') }}</label>
            <input :id="'nationality_' + passenger_ind" type="text" class="input bd-radius" v-model="passenger_data.nationality" :disabled="view_mode" />
          </div>
          <div class="w-30 px10">
            <label :for="'gender_' + passenger_ind">{{ $t('FLIGHTS.GENDER') }}</label>

            <vue-select
              class="input bd-radius cus-select"
              :id="'gender_' + passenger_ind"
              :options="gender_options"
              :clearable="false"
              :clearSearchOnSelect="false"
              :searchable="false"
              :reduce="t => t.code"
              v-model="passenger_data.gender"
              :disabled="view_mode"
            ></vue-select>

          </div>
          <div class="w-30 px10">
            <label :for="'birthday_' + passenger_ind">{{ $t('FLIGHTS.DATE_OF_BIRTH') }}</label>

            <Datepicker
              :id="'birthday_' + passenger_ind"
              v-model="passenger_data.birthday"
              @input="passenger_data.birthday = fixDate($event)"
              class="input cus-datepicker bd-radius"
              :class="{'disabled': view_mode}"
              :format="datepicker_format"
              :language="languages[currentLang]"
              :disabled="view_mode"
            ></Datepicker>
          </div>
        </div>
        <div class="d-flex justify-content-between w-100">
          <div class="w-50 px10">
            <label :for="'cardno_' + passenger_ind">{{ $t('FLIGHTS.CARD_NUMBER') }}</label>
            <input :id="'cardno_' + passenger_ind" type="text" class="input bd-radius" v-model="passenger_data.cardno" :disabled="view_mode" />
          </div>
          <div class="w-50 px10">
            <label :for="'expiration_' + passenger_ind">{{ $t('FLIGHTS.CARD_EXPIRATION') }}</label>
            <Datepicker
              :id="'expiration_' + passenger_ind"
              v-model="passenger_data.expiration"
              @input="passenger_data.expiration = fixDate($event)"
              class="input cus-datepicker bd-radius"
              :class="{'disabled': view_mode}"
              :format="datepicker_format"
              :language="languages[currentLang]"
              :disabled="view_mode"
            ></Datepicker>
          </div>
        </div>

        <div class="bg-white p10 bd-radius m-t-15 p-t-15">
          <template v-if="!hide_baggage">
            <div v-if="view_mode">
              <template v-if="passenger_data.hand_bag > 0 || passenger_data.hold_bag > 0">
                <h4 class="text-center">
                  {{ $t('FLIGHTS.BAGGAGE') }}
                </h4>
                <div>

                  <div v-if="passenger_data.category !== 'infants'" class="d-flex align-items-center baggage-title">
                    <img src="/assets/img/icons/side bag.svg" alt="">
                    <p class="mx10"><b>1 x {{ $t('FLIGHTS.PERSONAL_ITEM') }}</b></p>
                  </div>

                  <div v-if="passenger_data.hand_bag === 1" class="d-flex align-items-center baggage-title">
                    <img src="/assets/img/icons/caryonbag.svg" alt="">
                    <p class="mx10"><b>1 x {{ $t('FLIGHTS.CABIN_BAGGAGE') }}</b></p>
                  </div>

                  <div v-if="passenger_data.hold_bag > 0" class="d-flex align-items-center baggage-title">
                    <img src="/assets/img/icons/checkedbag.svg" alt="">
                    <p class="mx10"><b>{{ passenger_data.hold_bag === 2 ? '2 x ' : '1 x ' }}{{ $t('FLIGHTS.CHECKED_BAGGAGE') }}</b></p>
                  </div>

                </div>
              </template>
            </div>
            <div v-else class="row baggage-summary m-t-5">
              <div v-if="passenger_data.category !== 'infants' || baggage_availability.hand_bag_available" class="col-md-6 cabin-baggage">
                <h4 class="text-center">
                  {{ $t('FLIGHTS.CABIN_BAGGAGE') }}
                </h4>
                <p class="text-center fs12">{{ $t('FLIGHTS.SELECT_ONE_OPTION') }}</p>
                <div class="d-flex align-items-baseline m-t-10">
                  <input
                          class="mx15"
                          type="radio"
                          :name="'hand_bag_' + passenger_ind"
                          value="0"
                          v-model="passenger_data.hand_bag"
                          :checked="passenger_data.hand_bag == 0"
                  />
                  <div class="d-flex align-items-center radio-label bd-botton fs14 p-b-10 w-100">
                    <div class="d-flex align-items-center baggage-title">
                      <img src="/assets/img/icons/side bag.svg" alt="">
                      <p class="mx10"><b>{{ $t('FLIGHTS.PERSONAL_ITEM') }}</b></p>
                    </div>
                    <div class="size">
                      {{ pi_dimensions_text }}
                    </div>
                    <div class="price text-center">
                      <b>{{ 0 | formatPrice}} ₪</b>
                    </div>
                  </div>
                </div>
                <div v-if="baggage_availability.hand_bag_available" class="d-flex align-items-baseline m-t-10">
                  <input
                          class="mx15"
                          type="radio"
                          :name="'hand_bag_' + passenger_ind"
                          value="1"
                          v-model="passenger_data.hand_bag"
                          :checked="passenger_data.hand_bag == 1"
                  />
                  <div class="w-100">
                    <div class="d-flex align-items-center radio-label fs14 p-b-10 w-100">
                      <div class="d-flex align-items-center baggage-title">
                        <img src="/assets/img/icons/side bag.svg" alt="">
                        <p class="mx10"><b>{{ $t('FLIGHTS.PERSONAL_ITEM') }}</b></p>
                      </div>
                      <div class="size">
                        {{ pi_dimensions_text }}
                      </div>
                      <div class="price text-center">
                        <b>{{ hand_bagprice | formatPrice }} ₪</b>
                      </div>
                    </div>
                    <div class="d-flex align-items-center radio-label fs14 p-b-10 w-100">
                      <div class="d-flex align-items-center baggage-title">
                        <img src="/assets/img/icons/caryonbag.svg" alt="">
                        <p class="mx10"><b>{{ $t('FLIGHTS.CABIN_BAGGAGE') }}</b></p>
                      </div>
                      <div class="size">
                        {{ hand_dimensions_text }}
                      </div>
                      <div class="price text-center">
                        <b></b>
                      </div>
                    </div>
                    <div v-if="false" class="d-flex align-items-center radio-label fs14 p-b-10 w-100">
                      <img src="/assets/img/icons/caryonbag.svg" alt="">
                      <p class="mx10 fs10">
                        Priority Boarding is included for free in this bundle (Wizz Air). <b class="text-decoration-underline">Learn more</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="baggage_availability.hold_bag_available || baggage_availability.second_hold_bag_available" class="col-md-6 cabin-baggage">
                <h4 class="text-center">
                  {{ $t('FLIGHTS.CHECKED_BAGGAGE') }}
                </h4>
                <p class="text-center fs12">{{ $t('FLIGHTS.SELECT_ONE_OPTION') }}</p>
                <div class="d-flex align-items-baseline m-t-10">
                  <input
                          class="mx15"
                          type="radio"
                          :name="'hold_bag_' + passenger_ind"
                          value="0"
                          v-model="passenger_data.hold_bag"
                          :checked="passenger_data.hold_bag == 0"
                  />
                  <div class="d-flex align-items-center radio-label bd-botton fs14 p-b-10 w-100">
                    <img src="/assets/img/icons/X.svg" alt="">
                    <p class="mx10"><b>{{ $t('FLIGHTS.NO_CHECKED_BAGGAGE') }}</b></p>
                  </div>
                </div>
                <div v-if="hold_first_bag_available && baggage_availability.hold_bag_available" class="d-flex align-items-baseline m-t-10">
                  <input
                          class="mx15"
                          type="radio"
                          :name="'hold_bag_' + passenger_ind"
                          value="1"
                          v-model="passenger_data.hold_bag"
                          :checked="passenger_data.hold_bag == 1"
                  />
                  <div class="w-100">
                    <div class="d-flex align-items-center radio-label fs14 p-b-10 w-100">
                      <div class="d-flex align-items-center baggage-title">
                        <img src="/assets/img/icons/checkedbag.svg" alt="">
                        <p class="mx10"><b>{{ $t('FLIGHTS.CHECKED_BAGGAGE') }}</b></p>
                      </div>
                      <div class="size">
                        {{ hold_dimensions_text }}
                      </div>
                      <div class="price text-center">
                        <b>{{ hold_first_bagprice | formatPrice }} ₪</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="hold_second_bag_available && baggage_availability.second_hold_bag_available" class="d-flex align-items-baseline m-t-10">
                  <input
                          class="mx15"
                          type="radio"
                          :name="'hold_bag_' + passenger_ind"
                          value="2"
                          v-model="passenger_data.hold_bag"
                          :checked="passenger_data.hold_bag == 2"
                  />
                  <div class="w-100">
                    <div class="d-flex align-items-center radio-label fs14 p-b-10 w-100">
                      <div class="d-flex align-items-center baggage-title">
                        <img src="/assets/img/icons/checkedbag.svg" alt="">
                        <p class="mx10"><b>2 x {{ $t('FLIGHTS.CHECKED_BAGGAGE') }}</b></p>
                      </div>
                      <div class="size">
                        {{ hold_dimensions_text }}
                      </div>
                      <div class="price text-center">
                        <b>{{ hold_second_bagprice | formatPrice }} ₪</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-if="view_mode && show_edit_button && passenger_data.id && passenger_data.order_id">
            <button
              class="primary-btn primary-btn_sm bg-green6"
              @click="openEditPassengerModal">
              {{ $t( "FLIGHTS.EDIT" ) }}
            </button>
          </div>
          <div v-if="!view_mode && !hide_baggage && (baggage_availability.hold_bag_available || baggage_availability.second_hold_bag_available)" class="tip-section p15 fs12 bg-blue4 m-t-30">
            <b>{{ $t('FLIGHTS.TIP') }}</b> {{ $t('FLIGHTS.FLIGHT_TIP_TXT') }}
          </div>
        </div>
      </div>
    </div>

    <section v-if="view_mode && show_edit_button && passenger_data.id && passenger_data.order_id" class="edit-passenger-modal">

      <modal
        :width="600"
        :adaptive="true"
        class="CEModal modal-scroll-bar"
        :name="edit_passenger_modal_name"
        :ref="edit_passenger_modal_name"
        @closed="closeEditPassengerModal"
      >
        <button class="button cus-close-modal_btn" @click="closeEditPassengerModal">
          <img src="/assets/img/close.svg" alt />
        </button>


        <div class="m-t-50">

          <FlightPassengerInfo v-model="passenger_data" :passenger_ind="passenger_ind" :flight="flight" :view_mode="false" :show_edit_button="false" :hide_baggage="true" :is_modal="true" :bus="eventBus" :token="token"></FlightPassengerInfo>

        </div>

        <div class="pt20 m-b-20">
          <div class="d-flex align-items-end">
            <button
              class="primary-btn primary-btn_sm bg-green6"
              @click="savePassengerInfoHandler">
              {{ $t( "FLIGHTS.SAVE" ) }}
            </button>
            <button class="primary-btn primary-btn_sm bg-grey8" @click="closeEditPassengerModal">
              {{ $t( "COMMON.CLOSE" ) }}
            </button>
          </div>
        </div>

      </modal>
    </section>

    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Vue from 'vue';

export default {
  name: "FlightPassengerInfo",
  model: {
    prop: 'passenger',
    event: 'update',
  },
  props: {
    flight: {
      type: Object,
      default: null
    },
    passenger: {
      type: Object,
      default: null
    },
    passenger_ind: {
      type: Number,
      default: null
    },
    view_mode: {
      type: Boolean,
      default: false
    },
    show_edit_button: {
      type: Boolean,
      default: false
    },
    hide_baggage: {
      type: Boolean,
      default: false
    },
    token: {
      type: String,
      default: null
    },
    is_modal: {
      type: Boolean,
      default: false
    },
    bus: {
      type: Vue,
      default: null
    }
  },
  data() {
    return {
      eventBus: new Vue(),
      datepicker_format: 'yyyy-MM-dd',
      gender_options: [
        {
          code: 'male',
          label: this.$t("FLIGHTS.GENDER_MALE")
        },
        {
          code: 'female',
          label: this.$t("FLIGHTS.GENDER_FEMALE")
        },
      ],
      edit_passenger_modal_name: 'edit-passenger-' + this.passenger.id,
      passenger_data: {
        id: null,
        order_id: null,
        category: null,
        name: null,
        surname: null,
        nationality: null,
        gender: null,
        birthday: null,
        cardno: null,
        expiration: null,
        hand_bag: null,
        hold_bag: null,
      }
    };
  },
  created() {
    for (let field_name in this.passenger_data) {
      this.passenger_data[field_name] = field_name in this.passenger
        ? this.passenger[field_name] : null;
    }
  },
  components: {
  },
  methods: {
    ...mapActions('flights', {
      updateOrderPassengerInfo: 'updatePassengerInfo',
    }),
    ...mapActions("alert", {
      errorAlert: "error",
      infoAlert: "info",
    }),
    openEditPassengerModal() {
      this.$modal.show(this.edit_passenger_modal_name);
    },
    closeEditPassengerModal() {
      this.$modal.hide(this.edit_passenger_modal_name);
    },
    savePassengerInfoHandler() {
      this.eventBus.$emit('updatePassengerInfo');
    },
    async updatePassengerInfo() {
      await this.updateOrderPassengerInfo({
        id: this.passenger_data.id,
        order_id: this.passenger_data.order_id,
        token: this.token,
        passenger_info: {
          name: this.passenger_data.name,
          surname: this.passenger_data.surname,
          nationality: this.passenger_data.nationality,
          gender: this.passenger_data.gender,
          birthday: this.passenger_data.birthday,
          cardno: this.passenger_data.cardno,
          expiration: this.passenger_data.expiration,
        }
      }).then(
        result => {
          this.$emit('update', this.passenger_data);
          return result;
        },
        error => {
          this.errorAlert(this.$t("FLIGHTS.ERROR"));
          return Promise.reject(error);
        }
      );

      this.closeEditPassengerModal();
    },
    fixDate(value) {
      return moment(value).format('YYYY-MM-DD');
    }
  },
  computed: {
    ...mapState({
      update_passenger_info_in_process: (state) => state.flights.update_passenger_info_in_process,
    }),
    loading() {
      return this.update_passenger_info_in_process;
    },
    baggage_availability() {
      return this.flight
        && this.flight.baggage_availability
        && this.flight.baggage_availability[this.passenger_data.category]
          ? this.flight.baggage_availability[this.passenger_data.category] : null;
    },
    baglimit() {
      return this.flight && this.flight.baglimit ? this.flight.baglimit : null;
    },
    bagsprice() {
      return this.flight && this.flight.bagsprice ? this.flight.bagsprice : null;
    },
    hand_bagprice() {
      return this.bagsprice && this.bagsprice.hand ? this.bagsprice.hand : 0;
    },
    hold_first_bagprice() {
      return this.bagsprice && this.bagsprice.first_baggage !== null
        ? this.bagsprice.first_baggage : null;
    },
    hold_second_bagprice() {
      return this.bagsprice && this.bagsprice.second_baggage !== null
        ? this.bagsprice.second_baggage : null;
    },
    hold_first_bag_available() {
      return this.hold_first_bagprice !== null;
    },
    hold_second_bag_available() {
      return this.hold_second_bagprice !== null;
    },
    pi_baglimit() {
      return this.baglimit ? {
        height: this.baglimit.personal_item_height,
        length: this.baglimit.personal_item_length,
        weight: this.baglimit.personal_item_weight,
        width: this.baglimit.personal_item_width,
      } : null;
    },
    hand_baglimit() {
      return this.baglimit ? {
        height: this.baglimit.hand_height,
        length: this.baglimit.hand_length,
        weight: this.baglimit.hand_weight,
        width: this.baglimit.hand_width,
      } : null;
    },
    hold_baglimit() {
      return this.baglimit ? {
        height: this.baglimit.hold_height,
        length: this.baglimit.hold_length,
        weight: this.baglimit.hold_weight,
        width: this.baglimit.hold_width,
        dimensions_sum: this.baglimit.hold_dimensions_sum,
      } : null;
    },
    pi_dimensions_text() {
      // 40 × 20 × 30 cm, 10 kg
      let pi_dimensions_text = '';
      if (this.pi_baglimit) {
        pi_dimensions_text += this.pi_baglimit.length
          + ' x ' + this.pi_baglimit.width
          + ' x ' + this.pi_baglimit.height + ' cm'
          + (this.pi_baglimit.weight ? ', ' + this.pi_baglimit.weight + ' kg' : '')
      }
      return pi_dimensions_text;
    },
    hand_dimensions_text() {
      // 40 × 20 × 30 cm, 10 kg
      let hand_dimensions_text = '';
      if (this.hand_baglimit) {
        hand_dimensions_text += this.hand_baglimit.length
          + ' x ' + this.hand_baglimit.width
          + ' x ' + this.hand_baglimit.height + ' cm'
          + (this.hand_baglimit.weight ? ', ' + this.hand_baglimit.weight + ' kg' : '')
      }
      return hand_dimensions_text;
    },
    hold_dimensions_text() {
      // 40 × 20 × 30 cm (90 cm), 10 kg
      let hold_dimensions_text = '';
      if (this.hold_baglimit) {
        hold_dimensions_text += this.hold_baglimit.length
          + ' x ' + this.hold_baglimit.width
          + ' x ' + this.hold_baglimit.height + ' cm'
          + (this.hold_baglimit.dimensions_sum ? ' (' + this.hold_baglimit.dimensions_sum + ' cm)' : '')
          + (this.hold_baglimit.weight ? ', ' + this.hold_baglimit.weight + ' kg' : '')
      }
      return hold_dimensions_text;
    },
    category_title() {
        let category_title = '';

        switch (this.passenger_data.category) {
            case 'adults':
                category_title += this.$t('FLIGHTS.PASSENGER_CATEGORY_ADULTS') + ' - ' + this.$t('FLIGHTS.OVER_11');
                break;
            case 'children':
                category_title += this.$t('FLIGHTS.PASSENGER_CATEGORY_CHILDREN') + ' - ' + this.$t('FLIGHTS.AGE_2_11');
                break;
            case 'infants':
                category_title += this.$t('FLIGHTS.PASSENGER_CATEGORY_INFANTS') + ' - ' + this.$t('FLIGHTS.UNDER_2');
                break;
            default:
                break;
        }

        return category_title;
    },
  },
  mounted() {
    if (this.bus && this.is_modal) {
      this.bus.$off('updatePassengerInfo').$on('updatePassengerInfo', this.updatePassengerInfo);
    }
  },
  watch: {
    passenger_data: {
      handler() {
        if (!this.is_modal) {
          this.$emit('update', {...this.passenger_data});
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
    .vs--disabled,
    .vs--disabled [role="combobox"],
    .vs--disabled .vs__search,
    .vs--disabled .vs__open-indicator,
    div.disabled {
        background-color: lightgrey !important;
        border: none !important;
    }

    [aria-modal="true"] {
        .vdp-datepicker {
            position: static;
        }
        .vdp-datepicker__calendar {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>

<style lang="scss" scoped>
  input[disabled] {
    background-color: lightgrey !important;
    border: none !important;
  }
  .fs16 {
    font-size: 16px !important;
  }
  .share-icon {
    position: absolute;
    right: 12px;
    bottom: 12px;
    height: 20px;
  }
  .baggage-summary {
    .cabin-baggage {
      border-right: 1px solid #E7E7E8;
    }
    .bd-botton {
      border-bottom: 1px solid #c5c5c5;
    }
    .radio-label {
      .baggage-title {
        width: 40%;
      }
      .size {
        width: 40%;
      }
      .price {
        width: 20%;
      }
    }

  }
</style>